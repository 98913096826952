<template>
  <v-dialog
    v-model="modal"
    max-width="60vw">
    <v-form
      ref="dialog-form"
      @submit.prevent="onConfirm()">
      <v-card>
        <v-card-title style="justify-content: center;">
          {{ formData.tourName }}
        </v-card-title>

        <v-card-text>
          <v-divider class="mb-3" />
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-row justify-center align-center"
              style="gap: 1rem;">
              <v-btn
                v-for="rate in 5"
                :key="`rate-${rate}`"
                icon
                depressed
                color="gold"
                large
                :disabled="loading"
                @click="setRate(rate)">
                <v-icon>
                  {{ formData.rate >= rate ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="formData.comment"
                color="primary"
                label="เขียนรีวิวของคุณที่นี่"
                outlined
                hide-details
                dense
                :disabled="loading" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.fullName"
                color="primary"
                label="ชื่อผู้รีวิว"
                outlined
                hide-details
                dense
                :disabled="loading" />
            </v-col>
            <v-col cols="12">
              <date-picker-dialog
                v-model="formData.packageStartDate"
                format="D MMM BBBB"
                label="เดินทางเมื่อ"
                :rules="textBoxRules"
                :disabled="loading" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <div class="button-container">
            <v-btn
              color="primary"
              block
              :ripple="false"
              depressed
              :loading="loading"
              :disabled="formData.rate <= 0"
              @click="onConfirm()">
              ยืนยัน
            </v-btn>
            <v-btn
              color="primary"
              outlined
              block
              :ripple="false"
              depressed
              style="background-color: white;"
              :disabled="loading"
              @click="onCancel()">
              ยกเลิก
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import DatePickerDialog from '@/components/DatePickerDialog.vue'
import ReviewsProvider from '@/resources/reviews.provider'

const ReviewsService = new ReviewsProvider()

export default {
  components: { DatePickerDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    review: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      tourId: '',
      tourName: '',
      packageStartDate: '',
      orderId: '',
      member: null,
      fullName: '',
      rate: 0,
      comment: ''
    }
  }),
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    modal () {
      this.formData = {
        tourId: '',
        tourName: '',
        packageStartDate: '',
        orderId: '',
        member: null,
        fullName: '',
        rate: 0,
        comment: '',
        ...this.review
      }
    },
    review () {
      this.formData = {
        tourId: '',
        tourName: '',
        packageStartDate: '',
        orderId: '',
        member: null,
        fullName: '',
        rate: 0,
        comment: '',
        ...this.review
      }
    }
  },
  mounted () {
    this.formData = {
      tourId: '',
      tourName: '',
      packageStartDate: '',
      orderId: '',
      member: null,
      fullName: '',
      rate: 0,
      comment: '',
      ...this.review
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    setRate (rate) {
      this.formData.rate = rate
    },
    async onConfirm () {
      try {
        const valid = await this.$refs['dialog-form'].validate()

        if (!valid) {
          return
        }

        this.loading = true

        if (this.formData?.id) {
          await ReviewsService.updateItemById(this.formData)

          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'แก้ไขข้อมูลเสร็จสิ้น',
            type: 'success',
            timeout: 2000
          })
        } else {
          await ReviewsService.create(this.formData)

          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'สร้างข้อมูลเสร็จสิ้น',
            type: 'success',
            timeout: 2000
          })
        }

        this.$emit('confirm')
      } catch (error) {
        console.error('onCancel', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    onCancel () {
      this.modal = false
    }
  }
}
</script>

<style scoped>
.button-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}
</style>
