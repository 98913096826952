<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เงื่อนไขการยกเลิกการเดินทาง
      </div>
      <editor
        v-model="formData.cancelConditions.th"
        :disabled="loading" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        ข้อมูลสำคัญเกี่ยวกับโรงแรมที่พัก
      </div>
      <editor
        v-model="formData.accommodationInformation.th"
        :disabled="loading" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        ข้อมูลสำคัญเกี่ยวกับสายการบิน
      </div>
      <editor
        v-model="formData.airlineInformation.th"
        :disabled="loading" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เงื่อนไขและข้อควรทราบอื่นๆ ทั่วไป
      </div>
      <editor
        v-model="formData.additionalConditions.th"
        :disabled="loading" />
    </v-col>
  </v-row>
</template>

<script>
import Editor from '@/components/Editor.vue'

export default {
  components: { Editor },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow  {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
