<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      v-for="(highlight, f) in formData.highlights"
      :key="`highlight-${f}`"
      cols="12"
      md="10"
      class="pa-6">
      <v-row class="highlight-container">
        <v-col cols="12">
          <image-uploader
            v-model="highlight.imageUrl"
            :name="`highlight-images-${f}`"
            :max-file="1"
            label="รูปภาพสถานที่" />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            หัวข้อ
          </div>
          <v-text-field
            v-model="highlight.title"
            :rules="textBoxRules"
            color="primary"
            placeholder="ขี่อูฐกลางทะเลทราย"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            คำอธิบาย
          </div>
          <v-textarea
            v-model="highlight.description"
            :rules="textBoxRules"
            placeholder="ชมเมืองอเล็กซานเดรีย ตำนานความรักของพระนางคลีโอพัตรา สัมผัสความยิ่งใหญ่ของมหาพีระมิดแห่งกีซา..."
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col cols="12">
          <div
            class="d-flex justify-end"
            style="gap: 1rem;">
            <v-btn
              v-if="(f + 1) === formData.highlights.length"
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              @click="addHighlight()">
              <v-icon>
                mdi-plus-circle-outline
              </v-icon>
              <span>
                เพิ่มสถานที่เที่ยว
              </span>
            </v-btn>
            <v-btn
              v-if="formData.highlights.length > 1"
              color="error"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              @click="removeHighlight(f)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
              <span>
                ลบสถานที่เที่ยว
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  components: {
    ImageUploader
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ]
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addHighlight () {
      this.formData.highlights.push({
          title: '',
          description: '',
          imageUrl: []
        })
    },
    removeHighlight (index) {
      this.formData.highlights.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.highlight-container {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
