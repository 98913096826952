<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      cols="12"
      md="4">
      <SearchBox
        v-model="query.search"
        :disabled="loading"
        @search="getItems(true)" />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        class="table-border">
        <template #[`item.memberId`]="{ item }">
          {{ item.booker.memberId || '-' }}
        </template>
        <template #[`item.fullName`]="{ item }">
          {{ `${item.booker.firstName} ${item.booker.lastName}` }}
        </template>
        <template #[`item.followers`]="{ item }">
          {{ item.followers.length + 1 }}
        </template>
        <template #[`item.state`]="{ item }">
          <div
            class="px-4 py-3 rounded-pill my-2"
            :style="{
              width: 'fit-content',
              color: getState(item.state).color,
              backgroundColor: getState(item.state).bgColor,
              userSelect: 'none'
            }">
            {{ getState(item.state).text }}
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- <v-list-item
                :ripple="false"
                @click="detailItem(item)">
                <v-list-item-title>
                  ดูข้อมูล
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item
                :ripple="false"
                @click="editItem(item)">
                <v-list-item-title>
                  แก้ไข
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.state !== 'canceled'"
                :ripple="false"
                @click="confirmDialogToggle(item)">
                <v-list-item-title>
                  ยกเลิก
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          :length="totalPages"
          :total-visible="7"
          circle
          color="primary" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import OrdersProvider from '@/resources/orders.provider'

const OrdersService = new OrdersProvider()

export default {
  components: {
    SearchBox
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'รหัสออเดอร์',
        value: 'orderNo',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'รหัสลูกค้า',
        value: 'memberId',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'fullName',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'จำนวนผู้ร่วมเดินทาง',
        value: 'followers',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'booker.tel',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'state',
        class: 'table-header',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    states: [
      {
        text: 'รอตรวจสอบเอกสาร',
        value: 'inspecting',
        bgColor: '#DED6C8',
        color: '#000000'
      },
      {
        text: 'รอยืนยันการจอง',
        value: 'pending',
        bgColor: '#026EAA1A',
        color: '#000000'
      },
      {
        text: 'รอชำระเงิน',
        value: 'confirmed',
        bgColor: '#FFDF7D',
        color: '#000000'
      },
      {
        text: 'ชำระเงินเสร็จสิ้น',
        value: 'paid',
        bgColor: '#07BAC6',
        color: '#FFFFFF'
      },
      {
        text: 'ยกเลิก',
        value: 'canceled',
        bgColor: '#EB5757',
        color: '#FFFFFF'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: '',
      tourId: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.query.tourId = this.$route.params.id
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    getState (state) {
      const found = this.states.find((s) => s.value === state)
      return found || {
        text: state,
        value: state,
        bgColor: '#026EAA1A',
        color: '#000000'
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await OrdersService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onCancel (id) {
      try {
        this.loading = true

        await OrdersService.updateStateItemById(id, { state: 'canceled' })

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onCancel', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.$router.push({ name: 'OrderCreate' })
    },
    detailItem (item) {
      this.$router.push({
        name: 'OrderDetail',
        params: {
          id: item.id
        }
      })
    },
    editItem (item) {
      this.$router.push({
        name: 'OrderEdit',
        params: {
          id: item.orderNo
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะยกเลิกการจอง "${item.orderNo}" ใช่หรือไม่?`,
        onConfirm: () => this.onCancel(item.id)
      })
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
