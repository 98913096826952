import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class AemtitiesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/amenities', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/amenities/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/amenities', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/amenities/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/amenities/${id}`)
  }
}

export default AemtitiesProvider
