<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      cols="12"
      md="4">
      <SearchBox
        v-model="query.search"
        :disabled="loading"
        @search="getItems(true)" />
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="auto">
      <v-btn
        color="primary"
        outlined
        depressed
        block
        :ripple="false"
        style="width: fit-content; background-color: white;"
        @click="createItem()">
        <v-icon>
          mdi-pencil-outline
        </v-icon>
        <span>
          เขียนรีวิว
        </span>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        class="table-border">
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateFullFormat() }}
        </template>
        <template #[`item.fullName`]="{ item }">
          {{ item.member ? `${item.member.firstName} ${item.member.lastName}` : item.fullName }}
        </template>
        <template #[`item.status`]="{ item }">
          <div
            :class="getState(item.status).color"
            :style="{
              width: 'fit-content',
              userSelect: 'none'
            }">
            {{ getState(item.status).text }}
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.status === 'inactive'"
                :ripple="false"
                @click="confirmPublishDialogToggle(item)">
                <v-list-item-title>
                  เผยแพร่
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                @click="editItem(item)">
                <v-list-item-title>
                  แก้ไข
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                @click="confirmDialogToggle(item)">
                <v-list-item-title>
                  ลบ
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          :length="totalPages"
          :total-visible="7"
          circle
          color="primary" />
      </div>
    </v-col>
    <review-dialog
      v-model="dialog"
      :review="selectedReview"
      @confirm="onConfirm()" />
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import ReviewsProvider from '@/resources/reviews.provider'
import ReviewDialog from './ReviewDialog.vue'

const ReviewsService = new ReviewsProvider()

export default {
  components: {
    SearchBox,
    ReviewDialog
  },
  props: {
    tourName: {
      type: String,
      default: ''
    },
    packageStartDate: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'วันที่',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'คะแนนรีวิว',
        value: 'rate',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'fullName',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'รีวิว',
        value: 'comment',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'table-header',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    status: [
      {
        text: 'สาธารณะ',
        value: 'active',
        color: 'success--text'
      },
      {
        text: 'รอยืนยัน',
        value: 'inactive',
        color: 'warning--text'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 5,
      search: '',
      tourId: ''
    },
    totalPages: 1,
    dialog: false,
    selectedReview: null
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.query.tourId = this.$route.params.id
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    getState (status) {
      const found = this.status.find((s) => s.value === status)

      return found || {
        text: status,
        value: status,
        color: 'primary--text'
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await ReviewsService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await ReviewsService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onPublish (id) {
      try {
        this.loading = true

        await ReviewsService.updateItemStatusById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'เผยแพร่ข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onPublish', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.selectedReview = {
        tourId: this.$route.params.id,
        tourName: this.tourName,
        packageStartDate: this.packageStartDate
      }
      this.dialog = true
    },
    editItem (item) {
      this.selectedReview = item
      this.dialog = true
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: 'คุณยืนยันที่จะ "ลบ" รีวิวนี้ ใช่หรือไม่?',
        onConfirm: () => this.onRemove(item.id)
      })
    },
    confirmPublishDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: 'คุณยืนยันที่จะ​ "เผยแพร่" รีวิวนี้ ใช่หรือไม่?',
        onConfirm: () => this.onPublish(item.id)
      })
    },
    onConfirm () {
      this.dialog = false
      this.getItems(true)
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
