import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class ReviewsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/reviews', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/reviews/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/reviews', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/reviews/${payload.id}`, payload)
  }

  updateItemStatusById (id) {
    this.setHeader(getAuthToken())
    return this.put(`/reviews/${id}/publish`)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/reviews/${id}`)
  }
}

export default ReviewsProvider
